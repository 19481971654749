import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Crevette from '../images/imageCrevettes.jpeg';
import M from 'materialize-css/dist/js/materialize.min.js';


class MenuSaisonnier extends Component {
    componentDidMount() {
        let materialboxed = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(materialboxed);
      }

    render(){
        return (
            <div>
            <div  style={{ backgroundImage:`url(${Crevette})`, backgroundSize: 'cover' }}>
            <br/>
                <div className="container">
                <div className="green lighten-5" style={{borderRadius: 35}}>

                    

                        <h3 className="center green-text text-darken-2" style={{paddingTop: 15}}>Les menus saisonniers</h3>

                            
                            {/*<div className="row">

                                <div className="green lighten-5 col l6 offset-l3 s12" style={{borderRadius: 35}}>

                                    <img src={Promotion2} alt="Cuisinier" className="responsive-img materialboxed"/>

                                </div>

                            </div>            */}                
                            
                            
                                               

                <br/>

                    <NavLink to="/contact">
                        <button className="btn green darken-2 waves-effect waves-dark">Réserver une table</button>
                    </NavLink>

                    <br/><br/>



                </div>
                <br/><br/>
                </div>
                </div>

                
                </div>

            
        )
    }
}

export default MenuSaisonnier;